import Beetle from './assets/Beetle.png';
import Bug from './assets/Bug.png';
import FatWorm from './assets/Fat_Worm.png';
import GoldenWorm from './assets/Golden_Worm.png';
import Larva from './assets/Larva.png';
import Worm from './assets/Worm.png';
import nftplay from './assets/nftplay.png';
import sandbox from './assets/sandbox.png';
import web3GamingExpo from './assets/web3_gaming_expo.png';
import yuliverse from './assets/yuliverse.png';

export interface Vendor {
  id: string;
  name: string;
  about: string;
  howToJoin: string[];
  goToApp: string;
  goToApp2?: string;
  image: string;
  buttonText?: string;
  buttonText2?: string;
  disabled?: boolean;
  claimFunction?: () => void;
}

export const vendors: Vendor[] = [
  {
    id: 'Web3 Gaming Expo HK',
    name: 'Web3 Gaming Expo HK',
    about:
      '<a href="https://lu.ma/web3gamingexpo" target="_blank">​WEB3 GAMING EXPO</a> is a open-to-public one day expo in an event space spanning 8,500 square feet that occupies the second and third floors of Soho House Hong Kong, showcasing the latest 20+ web3 gaming projects. Claiming of HONG KONG DIRL PASS will have special IRL access within the event.',
    howToJoin: [
      'If you don’t already own a HONG KONG DIRL PASS. You can visit our booth to receive a physical NFC card, by tapping it you can claim the pass through NFTPlay.',
      'Visit the event and redeem special IRL rewards through vending machine via HONG KONG DIRL PASS.',
    ],
    goToApp: 'https://hongkongdirlpass-redeem.domin.foundation/campaigns/vendingmachineinweb3gamingexpo',
    image: web3GamingExpo,
    buttonText: 'Event closed',
    disabled: true,
  },
  {
    id: 'Sandbox',
    name: 'The Sandbox - Kowloon Walled City 2 : Walled City Rhapsody',
    about:
      'Travel through time to the year 2169 and explore the futuristic Kowloon Walled City to hunt for the IRL treasures! Claim the pass that gets you to various IRL rewards even in Metaverse.',
    howToJoin: [
      'Claim the above HONG KONG DIRL PASS in either Polygon or Conflux.',
      'Download & Install The Sandbox Application and visit “Kowloon Walled City 2: Walled City Rhapsody”.',
      'Visit the Metaverse city for IRL rewards!',
    ],
    goToApp:
      'https://www.sandbox.game/zh-TW/experiences/Kowloon%20Walled%20City%202:%20Walled%20City%20Rhapsody/7fbf5d07-6b49-4e5c-9dc6-feb1c925d0cc/page/',
    image: sandbox,
  },
  {
    id: 'Yuliverse',
    name: 'Yuliverse',
    about: 'Walk, explore & play in Hong Kong with a pass that connects with IRL rewards!',
    howToJoin: [
      'Claim the above HONG KONG DIRL PASS in Polygon.',
      'Download & Install Yuliverse application.',
      'Explore in Yuliverse for IRL rewards!',
    ],
    goToApp: 'https://play.google.com/store/apps/details?id=com.blockoor.yuliforoverseas&hl=en&gl=US',
    goToApp2: 'https://apps.apple.com/my/app/yuliverse/id6469319748',
    image: yuliverse,
    buttonText: 'Go to Google Play',
    buttonText2: 'Go to iOS App Store',
  },
  {
    id: 'NFTPlay',
    name: 'NFTPlay',
    about: 'Visit NFTPlay to celebrate with us and get free access to various IRL rewards!',
    howToJoin: ['Claim the above HONG KONG DIRL PASS in Conflux.', 'Visit NFTPlay website for IRL rewards!'],
    goToApp: 'https://domin-active.nftplay.fun',
    image: nftplay,
    buttonText: 'Go to app(Mobile only)',
  },
];
