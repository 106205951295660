import Beetle from './assets/Beetle.png';
import Bug from './assets/Bug.png';
import DominBeetle from './assets/Domin_Beetle.png';
import FatWorm from './assets/Fat_Worm.png';
import GoldenWorm from './assets/Golden_Worm.png';
import Larva from './assets/Larva.png';
import Worm from './assets/Worm.png';

export interface Vendor {
  id: string;
  name: string;
  about: string;
  howToJoin: string[];
  goToApp: string;
  goToApp2?: string;
  image: string;
  buttonText?: string;
  buttonText2?: string;
  disabled?: boolean;
  claimWormFlag?: boolean;
  claimDominBeetleFlag?: boolean;
  wormAddress: string;
  endDate?: string;
}

export const vendors: Vendor[] = [
  {
    id: 'Fat Worm',
    name: 'Fat Worm',
    about:
      'Fat Worm NFTs are the rewards for holders of the HK DIRL Pass. Each HK Pass NFT grants you one Fat Worm. The snapshot was taken on May 3rd at 15:59 UTC.',
    howToJoin: [],
    goToApp: '',
    image: FatWorm,
    buttonText: 'Claim',
    claimWormFlag: true,
    wormAddress: '',
    endDate: '2024/6/11 15:00',
  },
  {
    id: 'Worm',
    name: 'Worm',
    about:
      "Worm NFTs for those who've claimed over 50 Points from our Galxe Campaigns. Accumulate 50 points on Galxe to claim 1 Worm.",
    howToJoin: [],
    goToApp: '',
    image: Worm,
    buttonText: 'Claim',
    claimWormFlag: true,
    wormAddress: '',
    endDate: '2024/6/11 15:00',
  },
  {
    id: 'Beetle',
    name: 'Beetle',
    about: 'Registering one redemption page earns you 21 Beetles. The snapshot was taken on 5/3 at 15:59 UTC.',
    howToJoin: [],
    goToApp: '',
    image: Beetle,
    buttonText: 'Claim',
    claimWormFlag: true,
    wormAddress: '',
    endDate: '2024/6/11 15:00',
  },
  {
    id: 'Larva',
    name: 'Larva',
    about:
      "Larva NFTs for those who've claimed over 10 OATs from our Galxe Campaigns. Collect 10 OATs from our campaigns to receive 1 Larva.",
    howToJoin: [],
    goToApp: '',
    image: Larva,
    buttonText: 'Claim',
    claimWormFlag: true,
    wormAddress: '',
    endDate: '2024/6/11 15:00',
  },
  {
    id: 'Bug',
    name: 'Bug',
    about: 'Having the Bug role and submitting your address before the deadline in our Discord grants you 1 Bug.',
    howToJoin: [],
    goToApp: '',
    image: Bug,
    buttonText: 'Claim',
    wormAddress: '',
    claimWormFlag: true,
    endDate: '2024/6/11 15:00',
  },
  {
    id: 'Golden Worm',
    name: 'Golden Worm',
    about:
      'Having the Early Bird role and submitting your address before the deadline in our Discord grants you 1 Golden Worm.',
    howToJoin: [],
    goToApp: '',
    image: GoldenWorm,
    buttonText: 'Claim',
    wormAddress: '',
    claimWormFlag: true,
    endDate: '2024/6/11 15:00',
  },
  {
    id: '$Domin+Beetle',
    name: '$Domin+Beetle',
    about:
      'Broadcast your HK DIRL Pass redemption data onchain from Domin Network to earn $Domin and Beetles!<br><br>For each unique redemption data broadcast, you’ll receive an extra Beetle and some $Domin. The redemption data is based on transactions you have signed before.<br><br>The fee for broadcasting is 0.05 MATIC per redemption.<br><br>Please note that $Domin won’t be sent out immediately. We’ll save the records and allocate tokens later when the Airdrop event goes live. Additionally, the data will be put on-chain at a selected time.',
    howToJoin: [],
    goToApp: '',
    image: DominBeetle,
    buttonText: 'Claim',
    wormAddress: '',
    claimDominBeetleFlag: true,
    endDate: '2024/6/11 15:00',
  },
];
