import React from 'react';
import { StyledIconImg } from '@/styles/common';
import { hongkongpassImage } from '@/styles/imgMapping';
import { StyledContent, StyledTitle, StyledWrapper } from './Styles';
import { partners } from './partners';

const Partner = () => (
  <StyledWrapper>
    {partners.map((item) => (
      <div key={item.id}>
        <StyledTitle>{item.name}</StyledTitle>
        <StyledContent imageWidth={item.width} horizonGap={item.horizonGap} verticalGap={item.verticalGap}>
          {item.vendors.map((v) => (
            <StyledIconImg key={v.id} src={v.image} width={v.width || '100%'} mobileWidth={v.mobileWidth || '120px'} />
          ))}
        </StyledContent>
      </div>
    ))}
  </StyledWrapper>
);

export default Partner;
