import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { fontStyle, size } from '@/styles/common';

export const StyledWrapper = styled.div`
  max-width: 1370px;
  width: 100%;
  margin: 150px auto 0 auto;
`;

export const StyledTitle = styled.div`
  ${fontStyle('36px', '400')};
  color: #ff0000;
  font-family: 'Anton';
  margin-bottom: 46px;

  @media ${XS_QUERY} {
    text-align: center;
  }
`;

export const StyledDescription = styled.div`
  ${fontStyle('16px', '400')};
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Roboto';
  margin-bottom: 24px;
  line-height: 24px;

  @media ${XS_QUERY} {
    width: 100%;
  }
`;

export const StyledCardWrapper = styled.div<{ lastChild: boolean; isSingle?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isSingle }) => (isSingle ? '1fr' : '1fr 1fr')};
  grid-gap: 0 46px;
  padding-bottom: ${({ lastChild }) => (lastChild ? '0' : '92px')};
  margin-bottom: ${({ lastChild }) => (lastChild ? '150px' : '92px')};
  border-bottom: solid 1px ${({ theme }) => theme.colors.white};
  border-bottom-width: ${({ lastChild }) => (lastChild ? '0' : '1px')};

  @media ${XS_QUERY} {
    grid-template-columns: 1fr;
  }
`;

export const StyledContent = styled.div`
  @media ${XS_QUERY} {
    padding: 0 16px;
  }
`;
