import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { MaxWidth, Row, size } from '@/styles/common';

export const StyledWrapper = styled(Row)`
  padding: 24px;
  background: ${({ theme }) => theme.colors.black};
  border-top: solid 1px ${({ theme }) => theme.colors.black};

  @media ${SM_AND_UP_QUERY} {
    padding: 0;
    padding: 43px 0;
  }
`;

export const StyledContent = styled(Row)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;

  & > div {
    text-align: center;
  }

  @media ${SM_AND_UP_QUERY} {
    text-align: left;
    flex-direction: row;
    justify-content: center;
    ${MaxWidth}
  }
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.WE_D2};
  font-size: 10px;
  margin-top: 8px;
  margin-bottom: 16px;
  text-align: center;

  @media ${SM_AND_UP_QUERY} {
    margin-bottom: 0;
  }
`;

export const StyledImg = styled.div<{ src: string; hoverSrc: string }>`
  ${size('32px', '32px')};
  background: ${({ hoverSrc }) => `url(${hoverSrc})`};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  margin-right: 12px;
`;
