import styled, { css } from 'styled-components';
import { SM_AND_UP_QUERY } from '../breakpoints';

export const Row = styled.div<{
  justify?: string;
  align?: string;
  wrap?: string;
  useCursor?: boolean;
  direction?: string;
  mt?: string;
  mb?: string;
}>`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify};
  flex-wrap: ${({ wrap }) => wrap};
  cursor: ${({ useCursor }) => (useCursor ? 'pointer' : '')};
  margin-top: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
`;

export const StyledIconImg = styled.img<{
  width?: string;
  height?: string;
  marginRight?: string;
  marginLeft?: string;
  color?: string;
  mobileWidth?: string;
  mobileHeight?: string;
  mobileMarginRight?: string;
  mobileMarginLeft?: string;
  marginBottom?: string;
  cursor?: string;
  radius?: string;
}>`
  width: ${({ width, mobileWidth }) => mobileWidth || width};
  height: ${({ height, mobileHeight }) => mobileHeight || height};
  margin-right: ${({ marginRight, mobileMarginRight }) => mobileMarginRight || marginRight};
  margin-left: ${({ marginLeft, mobileMarginLeft }) => mobileMarginLeft || marginLeft};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  border-radius: ${({ radius }) => radius};

  @media ${SM_AND_UP_QUERY} {
    width: ${({ width }) => width};
    margin-right: ${({ marginRight }) => marginRight};
    margin-left: ${({ marginLeft }) => marginLeft};
  }
`;

export const StyledDesktopRender = styled.div`
  display: none;

  @media ${SM_AND_UP_QUERY} {
    display: block;
  }
`;

export const StyledMobileRender = styled.div`
  display: block;
  width: 100%;

  @media ${SM_AND_UP_QUERY} {
    display: none;
  }
`;

export const fontStyle = (size: string, weight?: string) => css`
  font-size: ${size};
  font-weight: ${weight || 'normal'};
`;

export const fontEllipsis = (endLine: number) => css`
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${endLine};
  -webkit-box-orient: vertical;
`;

export const size = (width: string, height: string) => css`
  width: ${width};
  height: ${height};
`;

export const MaxWidth = css`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
`;
