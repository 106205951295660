import React from 'react';
import { StyledIconImg } from '@/styles/common';
import { hongkongpassImage } from '@/styles/imgMapping';
import { StyledContent, StyledDescription, StyledTitle, StyledWrapper } from './Styles';

type Props = {
  data: {
    description: string;
    more: string[];
    image: string;
    title?: string;
  };
};

const Card = ({ data }: Props) => (
  <StyledWrapper>
    <StyledIconImg src={data.image} width="150px" height="150px" />
    <StyledContent>
      <StyledTitle>{data.title || 'Description'}</StyledTitle>
      <StyledDescription mb="16px" dangerouslySetInnerHTML={{ __html: data.description }} />
      {data.more.length > 0 && <StyledTitle>More</StyledTitle>}
      {data.more.map((description, index) => (
        <StyledDescription key={description} dangerouslySetInnerHTML={{ __html: description }} />
      ))}
    </StyledContent>
  </StyledWrapper>
);

export default Card;
