import { Method } from '../promise-request';
import type { RequestWormsParamsPayload } from './useRequestWormsParams';

const caller = ({ address, tokenId, signature }: RequestWormsParamsPayload) => {
  const version = 'v1';

  const url = `/${version}/hongKongDirlPass/worms/${tokenId}/${address}`;
  return {
    method: Method.POST,
    url,
    data: {
      signature,
    },
  };
};

export default caller;
