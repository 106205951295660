import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  max-width: 1370px;
  width: 100%;
  margin: 150px auto 0 auto;
`;

export const StyledTitle = styled.div`
  ${fontStyle('36px', '400')};
  color: #ff0000;
  font-family: 'Anton';
  margin-bottom: 10px;

  @media ${XS_QUERY} {
    text-align: center;
  }
`;

export const StyledDescription = styled.div`
  ${fontStyle('16px', '400')};
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Roboto';
  margin-bottom: 46px;
  width: 468px;
  line-height: 24px;

  @media ${XS_QUERY} {
    width: 100%;
    text-align: center;
  }
`;

export const StyledCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 200px));
  grid-gap: 92px 34px;

  @media ${XS_QUERY} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 16px;
    padding: 0 16px;
  }
`;
