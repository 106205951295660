import alcoholGhostImage from './assets/alcohol_ghost.svg';
import allstarsImage from './assets/allstars.svg';
import blazeImage from './assets/blaze.svg';
import chillpillImage from './assets/chillpill.svg';
import confluxImage from './assets/conflux.svg';
import curatorImage from './assets/curator.svg';
import cviewImage from './assets/cview.svg';
import ellipsisImage from './assets/ellipsis.svg';
import ensoImage from './assets/enso.svg';
import girlImage from './assets/girl.svg';
import hakubaImage from './assets/hakuba.svg';
import hoperImage from './assets/hoper.svg';
import indexGameImage from './assets/index_game.svg';
import jessyImage from './assets/jessy.svg';
import joyIdWalletImage from './assets/joyid_wallet.svg';
import lazybeanzImage from './assets/lazybeanz.svg';
import mbImage from './assets/mb.svg';
import mozyImage from './assets/mozy.svg';
import nftplayImage from './assets/nftplay.svg';
import nonelandImage from './assets/noneland.svg';
import okxWalletImage from './assets/okx_wallet.svg';
import onmImage from './assets/onm.svg';
import onusImage from './assets/onus.svg';
import panewsImage from './assets/panews.svg';
import polygonImage from './assets/polygon.svg';
import popmazeImage from './assets/popmaze.svg';
import rosemaeseImage from './assets/rosemaese.svg';
import sandboxImage from './assets/sandbox.svg';
import thaikauImage from './assets/thaikau.svg';
import trekkiImage from './assets/trekki.svg';
import tripImage from './assets/trip.svg';
import uberImage from './assets/uber.svg';
import xiaImage from './assets/xia.svg';
import yuliverseImage from './assets/yuliverse.svg';

export interface Partner {
  id: string;
  name: string;
  width?: string;
  horizonGap?: string;
  verticalGap?: string;
  vendors: {
    id: string;
    image: string;
    width?: string;
    mobileWidth?: string;
  }[];
}

export const partners: Partner[] = [
  {
    id: 'Strategic Partner',
    name: 'Strategic Partner',
    width: '200px',
    horizonGap: '38px',
    verticalGap: '46px',
    vendors: [
      {
        id: 'polygon',
        image: polygonImage,
        mobileWidth: '150px',
      },
      {
        id: 'sandbox',
        image: sandboxImage,
        mobileWidth: '150px',
      },
      {
        id: 'yuliverse',
        image: yuliverseImage,
        mobileWidth: '150px',
      },
      {
        id: 'nftplay',
        image: nftplayImage,
        mobileWidth: '150px',
      },
      {
        id: 'conluxx',
        image: confluxImage,
        mobileWidth: '150px',
      },
      {
        id: 'indexGame',
        image: indexGameImage,
        mobileWidth: '150px',
      },
      {
        id: 'okxWallet',
        image: okxWalletImage,
        mobileWidth: '150px',
      },
      {
        id: 'joyIdWallet',
        image: joyIdWalletImage,
        mobileWidth: '150px',
      },
    ],
  },
  {
    id: 'Community Partner',
    name: 'Community Partner',
    vendors: [
      {
        id: 'trekki',
        image: trekkiImage,
      },
      {
        id: 'alcoholGhost',
        image: alcoholGhostImage,
      },

      {
        id: 'allstars',
        image: allstarsImage,
      },
      {
        id: 'girl',
        image: girlImage,
      },
    ],
  },
  {
    id: 'Reward Partner',
    name: 'Reward Partner',
    vendors: [
      {
        id: 'onus',
        image: onusImage,
      },
    ],
  },
  {
    id: 'Brand Partner',
    name: 'Brand Partner',
    vendors: [
      {
        id: 'uber',
        image: uberImage,
      },
      {
        id: 'trip',
        image: tripImage,
      },
      {
        id: 'hakuba',
        image: hakubaImage,
      },
      {
        id: 'thaikau',
        image: thaikauImage,
      },
      {
        id: 'hoper',
        image: hoperImage,
      },
      {
        id: 'curator',
        image: curatorImage,
      },
      {
        id: 'mozy',
        image: mozyImage,
      },
      {
        id: 'rosemaese',
        image: rosemaeseImage,
      },
      {
        id: 'ellipsis',
        image: ellipsisImage,
      },
      {
        id: 'chillpill',
        image: chillpillImage,
      },
      {
        id: 'onm',
        image: onmImage,
      },
      {
        id: 'blaze',
        image: blazeImage,
      },
      {
        id: 'jessy',
        image: jessyImage,
      },
      {
        id: 'enso',
        image: ensoImage,
      },
      {
        id: 'popmaze',
        image: popmazeImage,
      },
      {
        id: 'xia',
        image: xiaImage,
        width: '49px',
        mobileWidth: '70px',
      },
      {
        id: 'cview',
        image: cviewImage,
        width: '79px',
        mobileWidth: '95px',
      },
    ],
  },
  {
    id: 'Media Partner',
    name: 'Media Partner',
    width: '200px',
    horizonGap: '28px',
    vendors: [
      {
        id: 'panews',
        image: panewsImage,
      },
      {
        id: 'noneland',
        image: nonelandImage,
      },
      {
        id: 'mb',
        image: mbImage,
        width: '78px',
        mobileWidth: '70px',
      },
      {
        id: 'lazybeanz',
        image: lazybeanzImage,
      },
    ],
  },
];
