import React from 'react';
import { Loading } from '@/components/Loading';
import useApplicationContext from '@/providers/applicationContext';
import { Banner } from './Banner';
import { Claim } from './Claim';
import { Experience } from './Experience';
import { Host } from './Host';
import { Notice } from './Notice';
import { Partner } from './Partner';
import { Rewards } from './Rewards';
import { StyledContent, StyledWrapper } from './Styles';
import { Worm } from './Worm';

const Home = () => {
  const { isLoading } = useApplicationContext();

  return (
    <StyledWrapper>
      <Loading isLoading={isLoading} />
      <StyledContent>
        <Banner />
        <Claim />
        <Experience />
        <Worm />
        <Rewards />
        <Host />
        <Partner />
        <Notice />
      </StyledContent>
    </StyledWrapper>
  );
};

export default Home;
