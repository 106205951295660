import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { Row, fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  padding-bottom: 46px;
  margin-bottom: 46px;
`;

export const StyledCustomRow = styled(Row)`
  align-items: flex-start;

  @media ${XS_QUERY} {
    flex-direction: column;
    align-items: center;

    & > img {
      width: 100%;
      padding: 0 16px;
    }
  }
`;

export const StyledContent = styled.div`
  flex: 1;
  margin-left: 24px;

  @media ${XS_QUERY} {
    margin-left: 0;
    margin-top: 24px;
    padding: 0 16px;
  }
`;

export const StyledTitle = styled.div`
  ${fontStyle('24px', '400')};
  font-family: 'Anton';
  margin-bottom: 24px;

  @media ${XS_QUERY} {
    text-align: center;
  }
`;

export const StyledSubTitle = styled.div`
  ${fontStyle('20px', '400')};
  font-family: 'Anton';
  margin-bottom: 8px;
`;

export const StyledDescription = styled.div`
  margin-bottom: 24px;
  ${fontStyle('16px', '400')};
  font-family: 'Roboto';
  line-height: 24px;

  a {
    color: #ff0000;
  }
`;

export const StyledList = styled.div`
  margin-bottom: 24px;
  padding-left: 0;
  line-height: 24px;
`;

export const StyledLi = styled.div`
  ${fontStyle('16px', '400')};
  font-family: 'Roboto';
`;

export const StyledButton = styled.div<{ disabled?: boolean }>`
  flex: 1;
  ${fontStyle('16px', '400')};
  font-family: 'Anton';
  border-radius: 6px;
  padding: 16px 0;
  text-align: center;
  cursor: pointer;
  border: solid 2px ${({ disabled }) => (disabled ? 'transparent' : '#ff0000')};
  color: ${({ disabled }) => (disabled ? '#737373' : '#ff0000')};
  background: ${({ disabled }) => (disabled ? '#e5e5e5' : 'transparent')};
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;

export const StyledRow = styled(Row)`
  & > div:last-child {
    margin-left: 8px;
  }
`;
