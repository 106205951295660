import styled from 'styled-components';
import { fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY, XS_QUERY } from '@/breakpoints';

export const StyledWrapper = styled.div`
  padding: 48px;
  margin-top: 32px;
  background: ${({ theme }) => theme.colors.black};
  border-top: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  border-bottom: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};

  @media ${SM_AND_UP_QUERY} {
    margin: 30px auto 0;
    width: 400px;
    border: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;

  & > button:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  ${fontStyle('20px', '700')};
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.white};

  @media ${SM_AND_UP_QUERY} {
    margin-bottom: 30px;
  }
`;

export const StyledClaimButton = styled.div<{ disabled?: boolean }>`
  margin-top: 24px;
  border-radius: 6px;
  padding: 16px 0;
  border: solid 2px ${({ theme, disabled }) => (disabled ? 'transparent' : theme.colors.white)};
  color: ${({ theme, disabled }) => (disabled ? '#737373' : theme.colors.white)};
  text-align: center;
  font-family: 'Anton';
  cursor: pointer;
  ${fontStyle('16px', '400')};
  background: ${({ disabled }) => (disabled ? '#e5e5e5' : 'transparent')};
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.colors.white};
    color: ${({ disabled }) => !disabled && '#ff0000'};
  }
`;
