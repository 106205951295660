import React from 'react';
import { StyledIconImg } from '@/styles/common';
import { Card } from './Card';
import { StyledCardWrapper, StyledContent, StyledDescription, StyledTitle, StyledWrapper } from './Styles';
import icon852web3 from './assets/852web3.svg';
import image852web3 from './assets/852web3Image.svg';
import dodobirdQuestion from './assets/dodobird_question.svg';
import domin from './assets/domin.svg';
import genmax from './assets/genmax.svg';
import genmaxLab from './assets/genmax_lab.svg';
import onchainCommerce from './assets/onchain_commerce.png';
import skytree from './assets/skytree.svg';
import skytreeImage from './assets/skytree_image.svg';

interface IHost {
  id: string;
  name: string;
  logo: string;
  description: string;
  cards: {
    description: string;
    more: string[];
    image: string;
    title?: string;
  }[];
}

const hosts: IHost[] = [
  {
    id: 'domin',
    name: 'domin',
    logo: domin,
    description: 'Domin Network is redefining retail and pioneering consumer data empowerment with blockchain.',
    cards: [
      {
        description:
          'This is the PFP pass for Domin Network’s reward campaign, unlocking $Domin as it advances through quests. Coming Soon...',
        more: [
          '1. Each redemption in HONG KONG DIRL PASS earns an airdrop of a Beetle  NFT! This NFT will give you an extra boost in our upcoming reward system. ',
          '2. Every HONG KONG DIRL PASS owner will be airdropped a Fat Worm NFT once it goes live!',
          '3. Stay tuned for our latest update on X: <a href="https://twitter.com/Domin_Network" target="_blank">https://twitter.com/Domin_Network</a> & Discord:<a href="http://join.domin.network/Discord" target="_blank">http://join.domin.network/Discord</a> ',
        ],
        image: dodobirdQuestion,
        title: 'Domin Network Community',
      },
      {
        description: 'Commerce+ is an organization program to encourage IRL commerce to work on on-chain commerce.',
        more: [
          '1. Every HONG KONG DIRL PASS owner will be airdropped a Commerce+ Profiles.',
          '2. Stay tuned at <a href="https://www.commerceplus.xyz/" target="_blank">https://www.commerceplus.xyz/</a> for participation of more rewards.',
          '3. Stay tuned for our latest update on X: <a href="https://twitter.com/Domin_Network" target="_blank">https://twitter.com/Domin_Network</a> & Discord: <a href="http://join.domin.network/Discord" target="_blank">http://join.domin.network/Discord</a>',
        ],
        image: onchainCommerce,
        title: 'Commerce+',
      },
    ],
  },
  {
    id: '852_web3',
    name: '852_web3',
    logo: icon852web3,
    description:
      'Most well-known Hong Kong Web3 community, taking the first step in leading cultural and community development in Hong Kong. The foremost catalyst for Web3 creator and community economy in Asia, developed a platform and suite tools to empower them in cultivating and leveraging their business value.',
    cards: [
      {
        description:
          'An open-to-public one day expo in an event space spanning 8,500 square feet that occupies the second and third floors of Soho House Hong Kong, showcasing the latest 20+ web3 gaming projects. Attendees have the unique opportunity to explore the latest and most exciting web3 gaming projects, presented by visionary teams in their thoughtfully designed exhibition booths.',
        more: [
          '1. Check <a href="https://lu.ma/web3gamingexpo" target="_blank">https://lu.ma/web3gamingexpo</a> for more info.',
        ],
        image: image852web3,
        title: 'Web3 Gaming Expo',
      },
    ],
  },
  {
    id: 'genmax_lab',
    name: 'genmax_lab',
    logo: genmaxLab,
    description: `Mastering Web2, Empowering Web3.
    Bridging the Present and Future: Merging Web2 Expertise with Web3 Innovation. At GenMax Lab, our mission is to seamlessly integrate traditional Web2 brands and mainstream markets with the transformative power of Web3 technologies. We're dedicated to driving mass adoption of Web3, providing Web2 brands with the strategies and tools they need to thrive in this new digital era, while also supporting Web3 brands in expanding their reach and influence across broader audiences.`,
    cards: [
      {
        description:
          'Leveraging our deep-rooted expertise in Web2 marketing strategies and an extensive understanding of the Web3 landscape, GenMax Lab has been pivotal in propelling business growth. We skilfully manage an annual online advertising budget exceeding US$20 million on behalf of over 40 corporate clients. Our diverse portfolio includes leading CEXs, as well as prominent names in the Banking and Finance sectors across Asia, showcasing our comprehensive approach to digital success in the evolving digital age.',
        more: [],
        image: genmax,
        title: 'One stop Web2 / Web3 Marketing Solutions',
      },
    ],
  },
  {
    id: 'skytree',
    name: 'skytree',
    logo: skytree,
    description:
      'Skytree Digital Limited, established in 2013, our mission is "Game your world"-We transform traditional digital applications with effective game design concepts. Gamificationis a proven technique in app design for user acquisition and boosting stickiness.',
    cards: [
      {
        description:
          "To promote the digitization process of local businesses in Hong Kong, Skytree, Domin Network GenMax Lab, and 852Web3 have joined forces to issue the innovative HONG KONG DIRL (Digital In Real Life) Pass, marking a new era of digital transformation for the region. This initiative is designed to integrate blockchain technology to unlock brand diversification and creative content, thus encouraging diverse economic development.<br><br>Centered around the concept of the HK Pass, the initiative has led to the creation of the Qpoint platform developed by Skytree. It's a comprehensive platform that combines online promotion, customer management, and special offers. Through the concept of the HONG KONG DIRL PASS, it aims to give consumers an early opportunity to explore the new possibilities brought about by blockchain, achieving seamless transition and interaction between brands and users.<br><br>This collaboration seeks to accelerate the pace of digitization in Hong Kong, creating a more connected and interactive business environment. It provides a novel digital experience platform for merchants and consumers alike, launching a new chapter in the commercial application of blockchain technology.",
        more: [],
        image: skytreeImage,
        title: 'Qpoint platform',
      },
    ],
  },
];

const Host = () => (
  <StyledWrapper>
    <StyledTitle id="Host & Partners">Host</StyledTitle>
    {hosts.map((host, index) => (
      <StyledContent key={host.id}>
        <StyledIconImg src={host.logo} width="181px" marginBottom="24px" />
        <StyledDescription dangerouslySetInnerHTML={{ __html: host.description }} />
        <StyledCardWrapper lastChild={hosts.length - 1 === index} isSingle={host.cards.length === 1}>
          {host.cards.map((data) => (
            <Card key={data.description} data={data} />
          ))}
        </StyledCardWrapper>
      </StyledContent>
    ))}
  </StyledWrapper>
);

export default Host;
