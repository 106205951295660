import React from 'react';
import { Card } from './Card';
import { StyledCardWrapper, StyledDescription, StyledTitle, StyledWrapper } from './Styles';
import { vendors } from './vendors';

const Rewards = () => (
  <StyledWrapper>
    <StyledTitle id="Rewards">REWARDS</StyledTitle>
    <StyledDescription>
      By participating in The Sandbox & Yuliverse experiences above, find your hidden rewards!
    </StyledDescription>
    <StyledCardWrapper>
      {vendors.map((vendor) => (
        <Card key={vendor.id} data={vendor} />
      ))}
    </StyledCardWrapper>
  </StyledWrapper>
);

export default Rewards;
