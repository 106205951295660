import React from 'react';
import { StyledContent, StyledDescription, StyledTitle, StyledWrapper } from './Styles';

const data = [
  {
    id: 'Notice',
    name: 'Notice',
    description:
      'All web3 or supporting technology used by HONG KONG DIRL PASS are not owned by HONG KONG DIRL PASS, and HONG KONG DIRL PASS is not able to control the actions or to monitor its information security. Please read the terms and conditions of use related to the use of the services and any related risk warnings provided by each service before using them.',
  },
  {
    id: 'FAQ',
    name: 'FAQ',
    description: '<a href="https://hkdirlpass.pse.is/5r2rs4" target="_blank">Check FAQ here.</a>',
  },
];

const Notice = () => (
  <StyledWrapper>
    <StyledContent>
      {data.map((d) => (
        <div key={d.id}>
          <StyledTitle>{d.name}</StyledTitle>
          <StyledDescription dangerouslySetInnerHTML={{ __html: d.description }} />
        </div>
      ))}
    </StyledContent>
  </StyledWrapper>
);

export default Notice;
