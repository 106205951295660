import { useCallback, useMemo, useState } from 'react';
import humps from 'humps';
import promiseRequest from '../promise-request';
import caller from './caller';

export interface RequestWormsParamsPayload {
  address: string;
  tokenId: number;
  signature: string;
}

export interface RequestWormsParamsResponse {
  signature: string;
  contractAddress: string;
  tokenId: string;
  amount: number;
  redemptionCount: number;
}

const transform = (data: any) => humps.camelizeKeys(data.data || data);

function useRequestWormsParams() {
  const requestAction = useMemo(() => promiseRequest(caller, transform), []);
  const [loading, setLoading] = useState(false);
  const handleRequest = useCallback((params: RequestWormsParamsPayload) => requestAction(params), [requestAction]);
  const requestWormsParams = useCallback(
    async (payload: RequestWormsParamsPayload): Promise<RequestWormsParamsResponse> => {
      setLoading(true);

      const { data, error } = await handleRequest(payload);
      setLoading(false);

      if (error) {
        return Promise.reject(error);
      }
      return Promise.resolve(data);
    },
    [handleRequest],
  );

  return {
    requestWormsParams,
    loadingRequestWormsParams: loading,
  };
}

export default useRequestWormsParams;
