import React, { useEffect, useState } from 'react';
import useGetTotalSupply from '@/api/getTotalSupply/useGetTotalSupply';
import useApplicationContext, { SupportChain } from '@/providers/applicationContext';
import { Row, StyledIconImg } from '@/styles/common';
import { BindingModal } from './BindingModal';
import {
  StyledClaimBg,
  StyledClaimButton,
  StyledClaimGrid,
  StyledClaimLink,
  StyledClaimTotal,
  StyledWrapper,
} from './Styles';
import { SuccessModal } from './SuccessModal';
import confluxIcon from './assets/conflux.svg';
import polygonIcon from './assets/polygon.svg';

const Claim = () => {
  const { setSelectChain, deactivateWallet, isApplicationMainnet, claimedMessage, setClaimedMessage, setClaimModal } =
    useApplicationContext();

  const { getTotalSupply } = useGetTotalSupply();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [polygonSupply, setPolygonSupply] = useState<number>(0);
  const [confluxSupply, setConfluxSupply] = useState<number>(0);

  const handleClaim = async (chain: SupportChain) => {
    await deactivateWallet();
    setOpenModal(true);
    setSelectChain(chain);
    setClaimModal(true);
  };

  useEffect(() => {
    const fetchPolygonData = async () => {
      try {
        const polygonChainId = isApplicationMainnet ? 137 : 80001;
        const { totalSupply } = await getTotalSupply({ chainId: polygonChainId });
        setPolygonSupply(totalSupply);
      } catch (error) {
        // error
      }
    };

    const fetchConfluxData = async () => {
      try {
        const confluxChainId = isApplicationMainnet ? 1030 : 71;
        const { totalSupply } = await getTotalSupply({ chainId: confluxChainId });
        setConfluxSupply(totalSupply);
      } catch (error) {
        // error
      }
    };

    Promise.all([fetchPolygonData(), fetchConfluxData()]);
  }, []);

  const openseaPolyonLink = isApplicationMainnet
    ? 'https://opensea.io/collection/hong-kong-dirl-pass'
    : 'https://testnets.opensea.io/collection/hong-kong-dirl-pass-1';
  const contractPolyonLink = isApplicationMainnet
    ? 'https://polygonscan.com/token/0xb91b28d1ce16f1792cede7fed34dfb15678e0d88'
    : 'https://mumbai.polygonscan.com/token/0x072870b56e6d5caa36d0f0ba798e3e997180732f';
  const contractConfluxLink = isApplicationMainnet
    ? 'https://evm.confluxscan.io/address/0x324021590bbdd427f104953bb06999d55f230c64'
    : '';

  return (
    <StyledWrapper>
      <SuccessModal
        opened={!!claimedMessage}
        title={claimedMessage?.title || ''}
        message={claimedMessage?.message || ''}
        onClose={() => setClaimedMessage(undefined)}
      />
      <BindingModal opened={openModal} onClose={() => setOpenModal(false)} />
      <StyledClaimGrid>
        <StyledClaimBg>
          <Row mb="16px" justify="space-between">
            <StyledIconImg src={polygonIcon} width="185px" mobileWidth="160px" />
            <StyledClaimTotal>{`Total claimed : ${polygonSupply}`}</StyledClaimTotal>
          </Row>
          <StyledClaimButton disabled>Claim</StyledClaimButton>
          <Row justify="center">
            <StyledClaimLink href={openseaPolyonLink} mr="24px" target="_blank">
              OpenSea
            </StyledClaimLink>
            <StyledClaimLink href={contractPolyonLink} target="_blank">
              Contract Address
            </StyledClaimLink>
          </Row>
        </StyledClaimBg>
        <StyledClaimBg>
          <Row mb="16px" justify="space-between">
            <StyledIconImg src={confluxIcon} width="185px" mobileWidth="160px" />
            <StyledClaimTotal>{`Total claimed : ${confluxSupply}`}</StyledClaimTotal>
          </Row>
          <StyledClaimButton disabled>Claim</StyledClaimButton>
          <Row justify="center">
            <StyledClaimLink href={contractConfluxLink} target="_blank">
              Contract Address
            </StyledClaimLink>
          </Row>
        </StyledClaimBg>
      </StyledClaimGrid>
    </StyledWrapper>
  );
};

export default Claim;
