import React, { useEffect, useState } from 'react';
import { signMessage as joyIdSignMessage } from '@joyid/evm';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { Modal } from '@components/Modal';
import { ClaimWormABI } from '@/abi/ClaimWormABI';
import useGetWorms from '@/api/getWorms/useGetWorms';
import useRequestWormsParams from '@/api/requestWormsParams/useRequestWormsParams';
import { joyIDProvider } from '@/configs/joyIDConfig';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { SuccessModal } from '../../Claim/SuccessModal';
import { StyledClaimButton, StyledContainer, StyledTitle, StyledWrapper } from './Styles';

type Props = {
  opened: boolean;
  tokenId?: number;
  wormName: string;
  onClose: () => void;
  setIsLoading: (v: boolean) => void;
};

const claimAddress = '0xdd895294c0755d5910Eca9D0325e5Fa1c933B22A';

const AmountModal = ({ opened, tokenId, wormName, onClose, setIsLoading }: Props) => {
  const { account, provider } = useWeb3React();
  const { joyIdAddress } = useJoyIdWallet();
  const { okxAddress, okxProvider } = useOKXWallet();

  const address = account || joyIdAddress || okxAddress;

  const [successModal, setSuccessModal] = useState<{ title: string; message: string }>({ title: '', message: '' });
  const [claimAmount, setClaimAmount] = useState<number>(0);

  const { getWorms, loadingGetWorms } = useGetWorms();
  const { requestWormsParams, loadingRequestWormsParams } = useRequestWormsParams();

  const getProvider = () => {
    if (account) {
      const sig = provider?.getSigner();
      return sig;
    }

    if (okxAddress) {
      const sig = okxProvider?.getSigner();
      return sig;
    }

    if (joyIdAddress) {
      const sig = joyIDProvider.getSigner();
      return sig;
    }

    return undefined;
  };

  const getSigature = async () => {
    if (account) {
      const sig = provider?.getSigner();
      const signature = await sig?.signMessage('HongKongDirlPass Claim Worm');
      return signature;
    }

    if (joyIdAddress) {
      const signature = await joyIdSignMessage('HongKongDirlPass Claim Worm', joyIdAddress);
      return signature;
    }

    if (okxAddress) {
      const signer = okxProvider?.getSigner();
      const signature = await signer?.signMessage('HongKongDirlPass Claim Worm');
      return signature;
    }

    return '';
  };

  const claimWorms = async () => {
    try {
      setIsLoading(true);
      const sig = await getSigature();
      const { contractAddress, amount, signature } = await requestWormsParams({
        address: address!,
        tokenId: tokenId!,
        signature: sig!,
      });

      const jsonRPCProvider = getProvider();
      if (jsonRPCProvider) {
        const claimWormContract = new ethers.Contract(claimAddress, ClaimWormABI, jsonRPCProvider);
        const { hash } = await claimWormContract.claim(contractAddress, address, tokenId, amount, signature);
        const txLink = `https://polygonscan.com/tx/${hash}`;
        setSuccessModal({
          title: 'Success',
          message: `You’ve claimed the DoDo Essentials. Check the <a href=${txLink} target="_blank">${txLink}</a> here.`,
        });
      }
    } catch (error) {
      setSuccessModal({ title: 'Error', message: JSON.stringify(error) });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { amount } = await getWorms({ address: address!, tokenId: tokenId! });
        setClaimAmount(amount);
      } catch (error) {
        setSuccessModal({
          title: 'Error',
          message: `Something got error [${JSON.stringify(error)}]`,
        });
      }
    };

    if (address && tokenId && tokenId < 7) {
      fetchData();
    }
  }, [account, joyIdAddress, okxAddress, tokenId]);

  useEffect(() => {
    setIsLoading(loadingGetWorms || loadingRequestWormsParams);
  }, [loadingGetWorms, loadingRequestWormsParams]);

  return (
    <Modal backgroundColor="transparent" disabledCloseButton opened={opened} onClose={onClose}>
      <StyledWrapper>
        <StyledTitle>{`${wormName}s Available`}</StyledTitle>
        <StyledContainer>{`Available [${wormName}] Quantity: ${claimAmount}`}</StyledContainer>
        {claimAmount > 0 && <StyledClaimButton onClick={claimWorms}>Claim</StyledClaimButton>}
      </StyledWrapper>
      <SuccessModal
        title={successModal.title}
        message={successModal.message}
        opened={!!successModal.title}
        onClose={() => setSuccessModal({ title: '', message: '' })}
      />
    </Modal>
  );
};

export default AmountModal;
