import styled from 'styled-components';
import { SM_AND_UP_QUERY, XS_QUERY } from '@/breakpoints';
import { starsImage } from '@/styles/imgMapping';

export const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  background-image: url(${starsImage});
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  padding-top: 62px;

  @media ${SM_AND_UP_QUERY} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledContent = styled.div`
  padding: 72px 0;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 0;
  }
`;
