/* eslint-disable react/forbid-prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { disconnect, initConfig } from '@joyid/evm';
import { useWeb3React } from '@web3-react/core';
import get from 'lodash/get';
import Web3 from 'web3';
import { SelectWallet, okxWallet } from '@configs/evmConnecters';
import { chainMapping } from '@configs/evmWalletConfigs';
import useGetIsClaimable from '@/api/getIsClaimable/useGetIsClaimable';
import useRequestClaimNFT from '@/api/requestClaimNFT/useRequestClaimNFT';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { AppError } from './pageProvider';

export enum SupportChain {
  Polygon = 'polygon',
  Conflux = 'conflux',
  None = 'None',
}

type Props = {
  children: React.ReactNode;
};

interface IChain {
  id: number;
  url: string;
  chainName: string;
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number | string;
  };
}

interface ClaimedMessage {
  title: string;
  message: string;
}

interface ContextType {
  web3?: Web3;
  currentChainType: string;
  isMainnet: boolean;
  contracts?: object;
  addressConfigs?: object;
  tokenNames?: any[];
  triggerSignWallet: boolean;
  setTriggerSignWallet: React.Dispatch<React.SetStateAction<boolean>>;
  appError: AppError | undefined;
  setAppError: React.Dispatch<React.SetStateAction<AppError | undefined>>;
  deactivateWallet: () => Promise<void>;
  handleMetamaskSwitchNetwork: (chain: IChain) => void;
  handleOKXSwitchNetwork: (chain: IChain) => void;
  handleJoyIdSwitchNetwork: (chain: IChain) => void;
  isJoyIdConnected: boolean;
  setIsJoyIdConnected: React.Dispatch<React.SetStateAction<boolean>>;
  selectChain: SupportChain;
  setSelectChain: React.Dispatch<React.SetStateAction<SupportChain>>;
  claimedMessage?: ClaimedMessage;
  setClaimedMessage: React.Dispatch<React.SetStateAction<ClaimedMessage | undefined>>;
  isLoading: boolean;
  isApplicationMainnet: boolean;
  setClaimModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectWallet: SelectWallet | undefined;
  setSelectWallet: React.Dispatch<React.SetStateAction<SelectWallet | undefined>>;
}

const ApplicationContext = createContext<ContextType>({
  web3: undefined,
  contracts: {},
  addressConfigs: {},
  tokenNames: [],
  currentChainType: '',
  isMainnet: false,
  triggerSignWallet: false,
  setTriggerSignWallet: () => {},
  appError: undefined,
  setAppError: () => {},
  deactivateWallet: () => Promise.resolve(),
  handleMetamaskSwitchNetwork: () => {},
  handleOKXSwitchNetwork: () => {},
  handleJoyIdSwitchNetwork: () => {},
  isJoyIdConnected: false,
  setIsJoyIdConnected: () => {},
  selectChain: SupportChain.Polygon,
  setSelectChain: () => {},
  claimedMessage: undefined,
  setClaimedMessage: () => {},
  isLoading: false,
  isApplicationMainnet: false,
  setClaimModal: () => {},
  selectWallet: undefined,
  setSelectWallet: () => {},
});

const isApplicationMainnet = (process.env.REACT_APP_NETWORK || 'TESTNET') === 'MAINNET';

const Provider = ({ children }: Props) => {
  const { chainId, account, provider, connector } = useWeb3React();
  const { okxAddress, okxChainId } = useOKXWallet();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();

  const userAddress = (account || '').toLowerCase();

  const [web3, setWeb3] = useState<Web3>();
  const [triggerSignWallet, setTriggerSignWallet] = useState<boolean>(false);
  const [appError, setAppError] = useState<AppError | undefined>(undefined);
  const [isJoyIdConnected, setIsJoyIdConnected] = useState<boolean>(false);
  const [selectChain, setSelectChain] = useState<SupportChain>(SupportChain.None);
  const [claimedMessage, setClaimedMessage] = useState<ClaimedMessage | undefined>();
  const [claimModal, setClaimModal] = useState<boolean>(false);
  const [selectWallet, setSelectWallet] = useState<SelectWallet | undefined>();

  const { requestClaimNFT, loadingRequestClaimNFT } = useRequestClaimNFT();
  const { getIsClaimable, loadingGetIsClaimable } = useGetIsClaimable();

  const isLoading = loadingRequestClaimNFT || loadingGetIsClaimable;

  const deactivateWallet = async () => {
    if (connector) {
      // metamask
      await connector.resetState();
      localStorage.clear();
      connector.provider = undefined;
      (connector as typeof connector & { eagerConnection: any }).eagerConnection = undefined;

      // okx
      await okxWallet.resetState();
      okxWallet.provider = undefined;
      (okxWallet as any).eagerConnection = undefined;

      // joyid
      setIsJoyIdConnected(false);
      disconnect();
    }
  };

  const handleMetamaskSwitchNetwork = async (chain: IChain) => {
    try {
      const { id } = chain;
      await connector.activate(id);
    } catch (error) {
      const { chainName, id, url, nativeCurrency } = chain;
      const hex = `0x${id.toString(16)}`;
      await connector.provider?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: hex,
            rpcUrls: [url],
            chainName,
            nativeCurrency,
          },
        ],
      });
    }
  };

  const handleOKXSwitchNetwork = async (chain: IChain) => {
    try {
      const { id } = chain;
      await okxWallet.activate(id);
    } catch (error) {
      const { chainName, id, url, nativeCurrency } = chain;
      const hex = `0x${id.toString(16)}`;
      await okxWallet.provider?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: hex,
            rpcUrls: [url],
            chainName,
            nativeCurrency,
          },
        ],
      });
    }
  };

  const handleJoyIdSwitchNetwork = (chain: IChain) => {
    initConfig({
      network: {
        chainId: chain.id,
        name: chain.chainName,
      },
    });
  };

  const isMainnet = get(chainMapping, [chainId as number, 'network']) === 'mainnet';

  useEffect(() => {
    if (provider) {
      const w = new Web3(provider as any);
      window.web3 = w;
      setWeb3(w);
    }
  }, [provider]);

  const claimHandler = [
    {
      id: 'metamask',
      address: userAddress,
      chainId,
      switchHandler: handleMetamaskSwitchNetwork,
    },
    {
      id: 'okx',
      address: okxAddress,
      chainId: okxChainId,
      switchHandler: handleOKXSwitchNetwork,
    },
    {
      id: 'joyid',
      address: joyIdAddress,
      chainId: joyIdchainId,
      switchHandler: handleJoyIdSwitchNetwork,
    },
  ];

  useEffect(() => {
    claimHandler.forEach((item) => {
      if (item.address && claimModal) {
        const handleLogin = async () => {
          try {
            if (selectChain === SupportChain.Polygon) {
              const polygonChainId = isApplicationMainnet ? 137 : 80001;
              const { isClaimed } = await getIsClaimable({
                address: item.address as string,
                chainId: polygonChainId,
              });
              if (!isClaimed) {
                const { tx } = await requestClaimNFT({
                  chainId: polygonChainId,
                  address: item.address as string,
                });
                const txLink = isApplicationMainnet
                  ? `https://polygonscan.com/tx/${tx}`
                  : `https://mumbai.polygonscan.com/tx/${tx}`;
                setClaimedMessage({
                  title: 'Success',
                  message: `You’ve claimed the HONG KONG DIRL PASS. Check the <a href=${txLink} target="_blank">${txLink}</a> here.`,
                });
              } else {
                setClaimedMessage({ title: 'Info', message: 'NFT can only be claimed once.' });
              }
            }
            if (selectChain === SupportChain.Conflux) {
              const confluxChainId = isApplicationMainnet ? 1030 : 71;
              const { isClaimed } = await getIsClaimable({
                address: item.address as string,
                chainId: confluxChainId,
              });
              if (!isClaimed) {
                const { tx } = await requestClaimNFT({
                  chainId: confluxChainId,
                  address: item.address as string,
                });
                const txLink = isApplicationMainnet
                  ? `https://evm.confluxscan.io/tx/${tx}`
                  : `https://evmtestnet.confluxscan.io/tx/${tx}`;
                setClaimedMessage({
                  title: 'Success',
                  message: `You’ve claimed the HONG KONG DIRL PASS. Check the <a href=${txLink} target="_blank">${txLink}</a> here.`,
                });
              } else {
                setClaimedMessage({ title: 'Info', message: 'NFT can only be claimed once.' });
              }
            }
          } catch (error) {
            setClaimedMessage({ title: 'Error', message: JSON.stringify(error) });
          }
        };
        handleLogin();
      }
    });
  }, [userAddress, joyIdAddress, okxAddress, claimModal]);

  const contexts = {
    web3,
    currentChainType: 'evm',
    isMainnet,
    triggerSignWallet,
    setTriggerSignWallet,
    appError,
    setAppError,
    deactivateWallet,
    handleMetamaskSwitchNetwork,
    handleOKXSwitchNetwork,
    handleJoyIdSwitchNetwork,
    isJoyIdConnected,
    setIsJoyIdConnected,
    selectChain,
    setSelectChain,
    claimedMessage,
    setClaimedMessage,
    isLoading,
    isApplicationMainnet,
    setClaimModal,
    selectWallet,
    setSelectWallet,
  };

  return <ApplicationContext.Provider value={contexts}>{children}</ApplicationContext.Provider>;
};

const useApplicationContext = () => useContext(ApplicationContext);

const ApplicationProvider = Provider;

export { ApplicationProvider };

export default useApplicationContext;
