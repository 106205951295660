import React from 'react';
import { Row, StyledIconImg } from '@/styles/common';
import { footerLogoImage } from '@/styles/imgMapping';
import { StyledContent, StyledImg, StyledText, StyledWrapper } from './Styles';
import { configs } from './configs';

const Footer = () => (
  <StyledWrapper>
    <StyledContent>
      <div>
        <StyledIconImg src={footerLogoImage} width="194px" />
        <StyledText>© 2024 by HONG KONG PASS. All rights reserved.</StyledText>
      </div>
      {/* <Row justify="center">
        {configs.map((item) => (
          <a href={item.link} key={item.id} target="_blank" rel="noreferrer">
            <StyledImg src={item.imgSrc} hoverSrc={item.imgHoverSrc} />
          </a>
        ))}
      </Row> */}
    </StyledContent>
  </StyledWrapper>
);

export default Footer;
