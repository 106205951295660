import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { Row, fontStyle } from '@/styles/common';

export const StyledWrapper = styled(Row)`
  align-items: flex-start;
  border: solid 1px ${({ theme }) => theme.colors.white};
  padding: 24px;

  @media ${XS_QUERY} {
    padding: 8px;
    flex-direction: column;
    align-items: center;

    & > img {
      margin-bottom: 8px;
    }
  }
`;

export const StyledContent = styled.div`
  margin-left: 16px;
  flex: 1;

  a {
    color: #ff0000;
  }
`;

export const StyledTitle = styled.div`
  ${fontStyle('20px', '600')};
`;

export const StyledDescription = styled.div<{ mb?: string }>`
  ${fontStyle('16px', '400')};
  line-height: 24px;
  margin-bottom: ${({ mb }) => mb};
`;
