import React from 'react';
import { Button, ButtonType } from '@redreamerlab/uikit';
import { Row, StyledIconImg } from '@styles/common';
import ghostPartyIcon from '../assets/img_ghost_party.svg';
import { StyledMessage, StyledModal, StyledTitle } from './Styles';

type Props = {
  title: string;
  opened: boolean;
  onClose: () => void;
  message: string;
};

const SuccessModal = ({ title, opened, onClose, message }: Props) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <StyledModal hasBackDrop opened={opened} onClose={handleClose} width="450" disabledCloseButton>
      <Row justify="space-between" align="center">
        <div>
          <StyledTitle>{title}</StyledTitle>
          {message && <StyledMessage dangerouslySetInnerHTML={{ __html: message }} />}
        </div>
        {/* <StyledIconImg src={ghostPartyIcon} alt="success" width="40px" /> */}
      </Row>
      <Row justify="center">
        <Button
          buttonType={ButtonType.CUSTOM}
          btnColor="#ffffff"
          borderColor="#ff0000"
          background="#ff0000"
          width="87px"
          onClick={handleClose}
          mt="32px"
        >
          OK
        </Button>
      </Row>
    </StyledModal>
  );
};

export default SuccessModal;
