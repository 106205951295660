import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Loading } from '@/components/Loading';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import useApplicationContext from '@/providers/applicationContext';
import { BindingModal } from '../Claim/BindingModal';
import { SwitchNetworkModal } from '../Claim/SwitchNetworkModal';
import { AmountModal } from './AmountModal';
import { Card } from './Card';
import { GetDominBeetleModal } from './GetDominBeetleModal';
import { StyledDescription, StyledTitle, StyledWrapper } from './Styles';
import { Vendor, vendors } from './vendors';

const description = `
Welcome to the reward claim page!<br><br>
Here, you can claim rewards based on your past activities with our HK Pass NFTs, Galxe quests, and roles in our Discord. The snapshot was taken on May 3rd at 15:59 UTC. Please review the reward details below.<br><br>
Please note that the period for verifying Reward NFT quantities has passed. We are unable to make adjustments after the claiming process.<br><br>
Ensure you connect the corresponding wallets where you minted your HK Pass, completed redemption, linked to your Galxe account, and submitted in Discord to redeem the rewards.
`;
const polygonMainet = 137;

enum ModalType {
  CLOSE,
  BINDING_MODAL,
  SWITCH_NETWORK_MODAL,
  AMOUNT_MODAL,
  GET_DOMIN_BEETLE_MODAL,
}

const Worm = () => {
  const { isJoyIdConnected } = useApplicationContext();

  const { account, chainId } = useWeb3React();
  const { okxAddress, okxChainId } = useOKXWallet();

  const [tokenId, setTokenId] = useState<number>();
  const [wormName, setWormName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModalType, setOpenModalType] = useState<ModalType>(ModalType.CLOSE);
  const [currentVendor, setCurrentVendor] = useState<Vendor | undefined>();

  const isOpenBindingModal = openModalType === ModalType.BINDING_MODAL;
  const isOpenAmountModal = openModalType === ModalType.AMOUNT_MODAL;
  const isOpenGetDominBeetleModal = openModalType === ModalType.GET_DOMIN_BEETLE_MODAL;
  const isOpenSwitchNetworkModal = openModalType === ModalType.SWITCH_NETWORK_MODAL;

  const closeModal = () => {
    setOpenModalType(ModalType.CLOSE);
  };

  useEffect(() => {
    const check = () => {
      if (isOpenBindingModal) {
        if ((account && chainId !== polygonMainet) || (okxAddress && okxChainId !== polygonMainet)) {
          setOpenModalType(ModalType.SWITCH_NETWORK_MODAL);
          return;
        }
      }

      if ((account && chainId === polygonMainet) || isJoyIdConnected || (okxAddress && okxChainId === polygonMainet)) {
        if (currentVendor?.claimWormFlag) {
          setOpenModalType(ModalType.AMOUNT_MODAL);
        }

        if (currentVendor?.claimDominBeetleFlag) {
          setOpenModalType(ModalType.GET_DOMIN_BEETLE_MODAL);
        }
      }
    };
    check();
  }, [account, isJoyIdConnected, okxAddress, chainId, okxChainId, currentVendor]);

  return (
    <StyledWrapper>
      <Loading isLoading={isLoading} />
      <StyledTitle id="Worm">Redeem Community Rewards</StyledTitle>
      <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
      {vendors.map((vendor, index) => (
        <Card
          key={vendor.id}
          vendor={vendor}
          openBindingModal={() => {
            setCurrentVendor(vendor);

            if (
              (account && chainId === polygonMainet) ||
              isJoyIdConnected ||
              (okxAddress && okxChainId === polygonMainet)
            ) {
              if (vendor.claimWormFlag) {
                setOpenModalType(ModalType.AMOUNT_MODAL);
              }

              if (vendor.claimDominBeetleFlag) {
                setOpenModalType(ModalType.GET_DOMIN_BEETLE_MODAL);
              }
            } else {
              setOpenModalType(ModalType.BINDING_MODAL);
            }
            setTokenId(index + 1);
            setWormName(vendor.name);
          }}
        />
      ))}
      <BindingModal opened={isOpenBindingModal} onClose={closeModal} />
      <AmountModal
        opened={isOpenAmountModal}
        onClose={closeModal}
        tokenId={tokenId}
        wormName={wormName}
        setIsLoading={(v) => setIsLoading(v)}
      />
      <GetDominBeetleModal
        opened={isOpenGetDominBeetleModal}
        onClose={closeModal}
        tokenId="3-1"
        wormName={wormName}
        setIsLoading={(v) => setIsLoading(v)}
      />
      <SwitchNetworkModal opened={isOpenSwitchNetworkModal} onClose={closeModal} />
    </StyledWrapper>
  );
};

export default Worm;
