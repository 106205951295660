import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { MaxWidth, Row, fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.black};
  border-bottom: solid 1px ${({ theme }) => theme.colors.WE_D2};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  @media ${XS_QUERY} {
    padding: 0 16px;
  }
`;

export const StyledContent = styled(Row)`
  ${MaxWidth};
  padding: 13px 0;
  justify-content: space-between;
`;

export const StyledListWrapper = styled.div<{ isActive: boolean }>`
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-gap: 24px;
  align-items: center;

  @media ${XS_QUERY} {
    grid-gap: 0;
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

export const StyledListItem = styled.a`
  ${fontStyle('16px', '500')};
  color: #ff0000;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${XS_QUERY} {
    padding: 16px;
    text-align: center;
    font-size: 24px;
  }
`;

export const StyledListRedeem = styled.a`
  ${fontStyle('16px', '500')};
  padding: 8px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: #ff0000;
  }

  @media ${XS_QUERY} {
    padding: 16px;
    text-align: center;
    font-size: 24px;
  }
`;

export const StyledHamburger = styled.div<{ isActive: boolean }>`
  display: none;
  width: 30px;
  height: 3px;
  border-radius: 10px;
  position: relative;
  background-color: ${({ theme, isActive }) => (isActive ? 'transparent' : theme.colors.white)};

  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    left: 0;
    top: ${({ isActive }) => (isActive ? '2px' : '-10px')};
    transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : 'rotate(0deg)')};
  }

  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    left: 0;
    top: ${({ isActive }) => (isActive ? '2px' : '10px')};
    transform: ${({ isActive }) => (isActive ? 'rotate(135deg)' : 'rotate(0deg)')};
  }

  @media ${XS_QUERY} {
    display: block;
  }
`;
