import { Button } from '@mui/material';
import styled from 'styled-components';
import { fontStyle, size } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { SelectWallet } from '@/configs/evmConnecters';

export const StyledImg = styled.div<{ src: string; selectWallet?: SelectWallet }>`
  background: ${({ src }) => `url(${src})`};
  background-size: ${({ selectWallet }) => (selectWallet === SelectWallet.OKX ? '75%' : 'cover')};
  background-position: ${({ selectWallet }) => (selectWallet === SelectWallet.OKX ? 'center' : 'top')};
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: 0 auto 12px auto;
  ${size('120px', '120px')};
  border: 1px solid #d9d9d9;
  background-color: white;
`;

export const StyledConnectTitle = styled.div`
  margin-bottom: 8px;
  margin-top: 30px;
  text-align: center;
  ${fontStyle('24px', '700')};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledConnectDescription = styled.div`
  margin-bottom: 30px;
  text-align: center;
  ${fontStyle('14px')};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledDisconnectButton = styled(Button)`
  &&& {
    ${size('100%', '40px')};
    ${fontStyle('0.875rem', '700')};
    padding: 12px 0;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.darkDream};
    text-transform: none;
    border: solid 1px ${({ theme }) => theme.colors.darkDream};
  }
`;

export const StyledWrapper = styled.div`
  padding: 48px;
  margin-top: 32px;
  background: ${({ theme }) => theme.colors.black};
  border-top: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  border-bottom: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};

  @media ${SM_AND_UP_QUERY} {
    margin: 30px auto 0;
    width: 400px;
    border: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  }
`;
