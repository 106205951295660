import React, { useState } from 'react';
import { StyledIconImg } from '@/styles/common';
import { footerLogoImage } from '@/styles/imgMapping';
import {
  StyledContent,
  StyledHamburger,
  StyledListItem,
  StyledListRedeem,
  StyledListWrapper,
  StyledWrapper,
} from './Styles';

const lists = [
  {
    id: 'Experiences',
    name: 'Experiences',
    link: '#Experiences',
  },
  {
    id: 'Rewards',
    name: 'Rewards',
    link: '#Rewards',
  },
  {
    id: 'Host & Partners',
    name: 'Host & Partners',
    link: '#Host & Partners',
  },
];

const Header = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <StyledWrapper>
      <StyledContent>
        <StyledIconImg src={footerLogoImage} width="194px" />
        <StyledHamburger isActive={isActive} onClick={() => setIsActive(!isActive)} />
        <StyledListWrapper isActive={isActive}>
          {lists.map((item) => (
            <StyledListItem key={item.id} href={item.link} onClick={() => setIsActive(false)}>
              {item.name}
            </StyledListItem>
          ))}
          <StyledListRedeem
            href="https://hongkongdirlpass-redeem.domin.foundation/"
            target="_blank"
            onClick={() => setIsActive(false)}
          >
            Redeem
          </StyledListRedeem>
        </StyledListWrapper>
      </StyledContent>
    </StyledWrapper>
  );
};

export default Header;
