import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  max-width: 1186px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledClaimGrid = styled.div`
  display: grid;
  grid-gap: 0 32px;
  margin-top: 150px;
  grid-template-columns: 1fr 1fr;

  @media ${XS_QUERY} {
    grid-template-columns: 1fr;

    & > div:first-child {
      margin-bottom: 16px;
    }
  }
`;

export const StyledClaimBg = styled.div`
  background: #ff000099;
  padding: 24px;
`;

export const StyledClaimTotal = styled.div`
  text-align: center;
  font-family: 'Anton';
  ${fontStyle('24px', '400')};

  @media ${XS_QUERY} {
    font-size: 18px;
  }
`;

export const StyledClaimButton = styled.div<{ disabled?: boolean }>`
  border-radius: 6px;
  padding: 16px 0;
  border: solid 2px ${({ theme, disabled }) => (disabled ? 'transparent' : theme.colors.white)};
  color: ${({ theme, disabled }) => (disabled ? '#737373' : theme.colors.white)};
  text-align: center;
  margin-bottom: 24px;
  font-family: 'Anton';
  cursor: pointer;
  ${fontStyle('24px', '400')};
  background: ${({ disabled }) => (disabled ? '#e5e5e5' : 'transparent')};
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.colors.white};
    color: ${({ disabled }) => !disabled && '#ff0000'};
  }

  @media ${XS_QUERY} {
    margin: 0 16px 24px 16px;
  }
`;

export const StyledClaimLink = styled.a<{ mr?: string }>`
  text-decoration: underline;
  margin-right: ${({ mr }) => mr};
  font-family: 'Roboto';
  color: ${({ theme }) => theme.colors.white};
  ${fontStyle('16px', '400')};
`;
