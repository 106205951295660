import { Method } from '../promise-request';
import type { GetWormsPayload } from './useGetWorms';

const caller = ({ address, tokenId }: GetWormsPayload) => {
  const version = 'v1';

  const url = `/${version}/hongKongDirlPass/worms/${tokenId}/${address}`;
  return {
    method: Method.GET,
    url,
  };
};

export default caller;
