import { Method } from '../promise-request';
import type { RequestClaimNFTPayload } from './useRequestClaimNFT';

const caller = (data: RequestClaimNFTPayload) => {
  const version = 'v1';

  const url = `/${version}/hongKongDirlPass/claim`;
  return {
    method: Method.POST,
    url,
    data,
  };
};

export default caller;
