import { useCallback, useMemo, useState } from 'react';
import humps from 'humps';
import promiseRequest from '../promise-request';
import caller from './caller';

export interface RequestClaimNFTPayload {
  address: string;
  chainId: number;
}

export interface RequestClaimNFTResponse {
  tx: string;
}

const transform = (data: any) => humps.camelizeKeys(data.data || data);

function useRequestClaimNFT() {
  const requestAction = useMemo(() => promiseRequest(caller, transform), []);
  const [loading, setLoading] = useState(false);
  const handleRequest = useCallback((params: RequestClaimNFTPayload) => requestAction(params), [requestAction]);
  const requestClaimNFT = useCallback(
    async (payload: RequestClaimNFTPayload): Promise<RequestClaimNFTResponse> => {
      setLoading(true);

      const { data, error } = await handleRequest(payload);
      setLoading(false);

      if (error) {
        return Promise.reject(error);
      }
      return Promise.resolve(data);
    },
    [handleRequest],
  );

  return {
    requestClaimNFT,
    loadingRequestClaimNFT: loading,
  };
}

export default useRequestClaimNFT;
