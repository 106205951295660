import React from 'react';
import { StyledIconImg } from '@/styles/common';
import { Vendor } from '../vendors';
import { StyledTitle, StyledWrapper } from './Styles';

type Props = {
  data: Vendor;
};

const Card = ({ data }: Props) => (
  <StyledWrapper>
    <StyledIconImg src={data.image} width="100%" />
    <StyledTitle>{data.name}</StyledTitle>
  </StyledWrapper>
);

export default Card;
