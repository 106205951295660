import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { GlobalStyle } from './globalStyle';
import { ApplicationProvider } from './providers/applicationContext';
import Router from './routes';
import theme from './styles/theme';

const App = () => (
  <BrowserRouter>
    <ApplicationProvider>
      <ThemeProvider theme={theme}>
        <Header />
        <Router />
        <Footer />
        <GlobalStyle />
      </ThemeProvider>
    </ApplicationProvider>
  </BrowserRouter>
);

export default App;
