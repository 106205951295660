import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Modal } from '@components/Modal';
import { SelectWallet, okxWallet } from '@configs/evmConnecters';
import useApplicationContext from '@providers/applicationContext';
import { chains } from '@/configs/evmWalletConfigs';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { walletConnectImg, walletMetamaskImg, walletOKXImg } from '@/styles/imgMapping';
import { ConnectButton } from '../ConnectButton';
import {
  StyledConnectDescription,
  StyledConnectTitle,
  StyledDisconnectButton,
  StyledImg,
  StyledWrapper,
} from './Styles';

const getSwitchNetworks = () => {
  const availableChains = chains
    .filter((chain) => chain.network === 'mainnet')
    .filter((chain) => chain.name === 'polygon');

  return availableChains.map((chain) => {
    const { display, id, rpc, metamaskDefaultRPC, nativeCurrency } = chain;
    return {
      id,
      url: metamaskDefaultRPC || rpc,
      chainName: display,
      nativeCurrency,
    };
  });
};

type Props = {
  opened: boolean;
  onClose: () => void;
};

const SwitchNetworkModal = ({ opened, onClose }: Props) => {
  const { account, connector } = useWeb3React();
  const { okxAddress } = useOKXWallet();

  const { deactivateWallet, selectWallet } = useApplicationContext();

  const handleSwitchNetworkByInjection =
    (chain: {
      id: number;
      url: string;
      chainName: string;
      nativeCurrency?: {
        name: string;
        symbol: string;
        decimals: number | string;
      };
    }) =>
    async () => {
      try {
        const { id } = chain;
        await connector.activate(id);
      } catch (error) {
        const { chainName, id, url, nativeCurrency } = chain;
        const hex = `0x${id.toString(16)}`;
        await connector.provider?.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: hex,
              rpcUrls: [url],
              chainName,
              nativeCurrency,
            },
          ],
        });
      }
    };

  const handleSwitchNetworkByOKX =
    (chain: {
      id: number;
      url: string;
      chainName: string;
      nativeCurrency?: {
        name: string;
        symbol: string;
        decimals: number | string;
      };
    }) =>
    async () => {
      try {
        const { id } = chain;
        await okxWallet.activate(id);
      } catch (error) {
        const { chainName, id, url, nativeCurrency } = chain;
        const hex = `0x${id.toString(16)}`;
        await okxWallet.provider?.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: hex,
              rpcUrls: [url],
              chainName,
              nativeCurrency,
            },
          ],
        });
      }
    };

  const handleSwitchNetwork =
    (chain: {
      id: number;
      url: string;
      chainName: string;
      nativeCurrency?: {
        name: string;
        symbol: string;
        decimals: number | string;
      };
    }) =>
    async () => {
      if (account) {
        handleSwitchNetworkByInjection(chain)();
      }
      if (okxAddress) {
        handleSwitchNetworkByOKX(chain)();
      }
    };

  const handleUnbind = async () => {
    await deactivateWallet();
    onClose();
  };

  const getWalletImage = () => {
    switch (selectWallet) {
      case SelectWallet.META_MASK:
        return walletMetamaskImg;
      case SelectWallet.WALLET_CONNECT:
        return walletConnectImg;
      case SelectWallet.OKX:
        return walletOKXImg;
      default:
        return '';
    }
  };

  const convertAddressToSimple = () => {
    const address = account || okxAddress;
    const prefixStr = address?.substring(0, 6) || '';
    const suffixStr = address?.substring(address.length - 4) || '';
    return `${prefixStr}...${suffixStr}`;
  };

  return (
    <Modal backgroundColor="transparent" disabledCloseButton opened={opened} onClose={onClose}>
      <StyledWrapper>
        <StyledImg src={getWalletImage()} selectWallet={selectWallet} />
        <StyledConnectDescription>{convertAddressToSimple()}</StyledConnectDescription>
        <StyledConnectTitle>Switch Network</StyledConnectTitle>
        <StyledConnectDescription>Sign the message with your wallet in order to continue.</StyledConnectDescription>
        {getSwitchNetworks().map((network) => (
          <ConnectButton name={network.chainName.toUpperCase()} onClick={handleSwitchNetwork(network)} />
        ))}
        <StyledDisconnectButton onClick={handleUnbind}>Disconnect</StyledDisconnectButton>
      </StyledWrapper>
    </Modal>
  );
};

export default SwitchNetworkModal;
