import accountIcon from '../assets/account_icon.svg';
import discordIcon from '../assets/discord.svg';
import discordHoverIcon from '../assets/discord_hover.svg';
import emailIcon from '../assets/email.svg';
import emailHoverIcon from '../assets/email_hover.svg';
import ethereumIcon from '../assets/ethereum.svg';
import facebookIcon from '../assets/facebook.svg';
import facebookHoverIcon from '../assets/facebook_hover.svg';
import footerLogo from '../assets/footer_logo.svg';
import headerLogo from '../assets/header_logo.svg';
import homeLogo from '../assets/home_logo.svg';
import hongkongpass from '../assets/hongkongpass.png';
import walletJoyId from '../assets/img_joyid.svg';
import inputClearIcon from '../assets/input_clear.svg';
import instagramIcon from '../assets/instagram.svg';
import instagramHoverIcon from '../assets/instagram_hover.svg';
import lineIcon from '../assets/line.svg';
import lineHoverIcon from '../assets/line_hover.svg';
import linkedinIcon from '../assets/linkedin.svg';
import linkinedHoverIcon from '../assets/linkined_hover.svg';
import lockIcon from '../assets/lock.svg';
import lockBlackIcon from '../assets/lock_black.svg';
import logoutIcon from '../assets/logout.svg';
import moreIcon from '../assets/more.svg';
import msgsenderIcon from '../assets/msgsender.svg';
import msgsenderHoverIcon from '../assets/msgsender_hover.svg';
import notFoundIcon from '../assets/not_found.svg';
import polygonIcon from '../assets/polygon.svg';
import searchIcon from '../assets/search.svg';
import sortIcon from '../assets/sort.svg';
import stars from '../assets/stars.svg';
import telegramIcon from '../assets/telegram.svg';
import telegramHoverIcon from '../assets/telegram_hover.svg';
import titleLogo from '../assets/title-logo.svg';
import twitterIcon from '../assets/twitter.svg';
import twitterHoverIcon from '../assets/twitter_hover.svg';
import walletMetamask from '../assets/wallet-metamask.svg';
import walletIcon from '../assets/wallet.svg';
import walletConnect from '../assets/wallet_connect.svg';
import walletOKX from '../assets/wallet_okx.svg';
import websiteIcon from '../assets/website.svg';
import websiteHoverIcon from '../assets/website_hover.svg';
import whiteCloseIcon from '../assets/white_close.svg';

export const accountIconImage = accountIcon;
export const whiteCloseIconImage = whiteCloseIcon;
export const websiteIconImage = websiteIcon;
export const emailIconImage = emailIcon;
export const twitterIconImage = twitterIcon;
export const facebookIconImage = facebookIcon;
export const telegramIconImage = telegramIcon;
export const discordIconImage = discordIcon;
export const msgsenderIconImage = msgsenderIcon;
export const lineIconImage = lineIcon;
export const instagramIconImage = instagramIcon;
export const linkedinIconImage = linkedinIcon;
export const websiteHoverIconImage = websiteHoverIcon;
export const emailHoverIconImage = emailHoverIcon;
export const twitterHoverIconImage = twitterHoverIcon;
export const facebookHoverIconImage = facebookHoverIcon;
export const telegramHoverIconImage = telegramHoverIcon;
export const discordHoverIconImage = discordHoverIcon;
export const msgsenderHoverIconImage = msgsenderHoverIcon;
export const lineHoverIconImage = lineHoverIcon;
export const instagramHoverIconImage = instagramHoverIcon;
export const linkinedHoverIconImage = linkinedHoverIcon;
export const polygonIconImage = polygonIcon;
export const ethereumIconImage = ethereumIcon;
export const moreIconImage = moreIcon;
export const searchIconImage = searchIcon;
export const sortIconImage = sortIcon;
export const inputClearIconImage = inputClearIcon;
export const lockIconImage = lockIcon;
export const walletIconImage = walletIcon;
export const lockBlackIconImage = lockBlackIcon;
export const logoutIconImage = logoutIcon;
export const notFoundIconImage = notFoundIcon;

export const homeLogoImage = homeLogo;
export const headerLogoImage = headerLogo;
export const walletMetamaskImg = walletMetamask;
export const walletConnectImg = walletConnect;
export const walletJoyID = walletJoyId;
export const footerLogoImage = footerLogo;
export const starsImage = stars;
export const hongkongpassImage = hongkongpass;
export const titleLogoImage = titleLogo;
export const walletOKXImg = walletOKX;
