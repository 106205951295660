import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { Row, fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  max-width: 940px;
  width: 100%;
  margin: 150px auto 0 auto;
`;

export const StyledTitle = styled.div`
  ${fontStyle('36px', '400')};
  color: #ff0000;
  font-family: 'Anton';
  margin-bottom: 10px;

  @media ${XS_QUERY} {
    text-align: center;
  }
`;

export const StyledDescription = styled.div`
  ${fontStyle('16px', '400')};
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Roboto';
  margin-bottom: 46px;
  line-height: 24px;

  @media ${XS_QUERY} {
    width: 100%;
    text-align: center;
  }
`;

export const StyledTabWrapper = styled(Row)`
  padding: 8px;
  margin-bottom: 46px;
  border: solid 1px ${({ theme }) => theme.colors.white};

  @media ${XS_QUERY} {
    display: none; // TODO
  }
`;

export const StyledTab = styled.a`
  font-family: 'Anton';
  margin-right: 10px;
  padding: 4px 24px;
  border-radius: 4px;
  cursor: pointer;
  ${fontStyle('20px', '400')};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
  }
`;
