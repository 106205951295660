import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { fontEllipsis, fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  width: 200px;

  @media ${XS_QUERY} {
    width: 100%;
  }
`;

export const StyledTitle = styled.div`
  font-family: 'Anton';
  ${fontStyle('20px', '400')};
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 30px;
`;

export const StyledDescription = styled.div`
  font-family: 'Roboto';
  ${fontStyle('16px', '400')};
  margin-bottom: 16px;
  ${fontEllipsis(2)};
  line-height: 24px;
`;

export const StyledQuantity = styled.div`
  font-family: 'Anton';
  ${fontStyle('20px', '400')};
`;
