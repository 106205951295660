import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { Row, fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  align-items: flex-start;

  @media ${XS_QUERY} {
    flex-direction: column;
  }
`;

export const StyledContent = styled(Row)`
  align-items: flex-start;

  @media ${XS_QUERY} {
    flex-direction: column;
  }
`;

export const StyledGlitch = styled.div`
  @media ${XS_QUERY} {
    width: 100%;
  }
`;

export const StyledClaimWrapper = styled.div`
  margin-left: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 580px;

  @media ${XS_QUERY} {
    margin-left: 0;
    margin-top: 24px;
    width: 100%;

    & > img {
      padding: 0 16px;
    }
  }
`;

export const StyledClaimText = styled.div`
  font-family: 'Roboto';
  line-height: 30px;
  ${fontStyle('20px', '400')};

  @media ${XS_QUERY} {
    padding: 0 16px;
    margin-top: 24px;
    text-align: center;
  }
`;
