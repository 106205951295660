import React from 'react';
import { Home } from '@pages/Home';

export default [
  {
    id: 'home',
    name: 'Home',
    path: '/',
    component: (props: any) => <Home {...props} description="Home" />,
  },
];
