import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledTitle = styled.div`
  ${fontStyle('36px', '400')};
  color: #ff0000;
  font-family: 'Anton';
  margin-bottom: 46px;

  @media ${XS_QUERY} {
    text-align: center;
  }
`;

export const StyledContent = styled.div<{ imageWidth?: string; horizonGap?: string; verticalGap?: string }>`
  display: grid;
  grid-template-columns: ${({ imageWidth }) => `repeat(auto-fill, minmax(0, ${imageWidth || '120px'}))`};
  grid-gap: ${({ horizonGap, verticalGap }) => `${verticalGap || '20px'} ${horizonGap || '43px'}`};
  justify-items: center;
  margin-bottom: 150px;
  align-items: center;

  @media ${XS_QUERY} {
    padding: 0 16px;
    display: grid;
    grid-template-columns: 150px 150px;
    justify-content: center;
    grid-gap: ${({ horizonGap, verticalGap }) => `${verticalGap || '16px'} ${horizonGap || '16px'}`};

    & > img {
      /* margin-right: 16px;
      margin-bottom: 16px; */
    }
  }
`;
