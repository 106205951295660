import React from 'react';
import styled from 'styled-components';
import { Row } from '@styles/common';

const StyledContainer = styled(Row)<{ isLoading?: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const StyledText = styled.div`
  font-size: 20px;
  color: white;
`;

type Props = {
  isLoading: boolean;
};

const Loading = ({ isLoading }: Props) => {
  if (!isLoading) return null;

  return (
    <StyledContainer justify="center" isLoading={isLoading}>
      <Row>
        <StyledText>Loading...</StyledText>
      </Row>
    </StyledContainer>
  );
};

export default Loading;
