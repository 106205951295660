import blazeCrattImage from './assets/blaze_cratt.svg';
import chillPillHKImage from './assets/chill_pill_hk.svg';
import curatorCreativeCafeImage from './assets/curator_creative_cafe.svg';
import cviewmage from './assets/cview.svg';
import EllipsisCafeImage from './assets/ellipsis_cafe.svg';
import ensoImage from './assets/enso.svg';
import hoperImage from './assets/hoper.svg';
import jessyCoffeeSandoImage from './assets/jessy_coffee_sando.svg';
import loungeHakubaImage from './assets/lounge_hakuba.svg';
import mozyCafeImage from './assets/mozy_cafe.svg';
import ohmCafeImage from './assets/ohm_cafe.svg';
import parkNShopImage from './assets/park_n_shop.svg';
import popmazeCafeImage from './assets/popmaze_cafe.svg';
import rosemaImage from './assets/rosema_ese.svg';
import thaiKauImage from './assets/thai_kau.svg';
import tripImage from './assets/trip.svg';
import uberImage from './assets/uber.svg';
import xiaImage from './assets/xia.svg';

export interface Vendor {
  id: string;
  name: string;
  description: string;
  image: string;
}

export const vendors: Vendor[] = [
  {
    id: 'Uber - HK$25 off voucher',
    name: 'Uber - HK$25 off voucher',
    description: 'Book an uber ride at a discount with your HONG KONG DIRL PASS. Unlock your perk right now!',
    image: uberImage,
  },
  {
    id: 'Trip.com - hotel 5% off coupon',
    name: 'Trip.com - hotel 5% off coupon',
    description: 'By participating in experiences, find your way to various IRL rewards.',
    image: tripImage,
  },
  {
    id: 'PARKnSHOP / Hung Fook Tong / Pacific Coffee / Wing Wah - HK$50 off voucher',
    name: 'PARKnSHOP / Hung Fook Tong / Pacific Coffee / Wing Wah - HK$50 off voucher',
    description:
      'Claim the discounted voucher with your HONG KONG DIRL PASS for brands below: 1.PARKnSHOP 2.Hung Fook Tong 3.Pacific Coffee 4.Wing Wah  Please refer to the terms and details on On-us before using the vouchers.',
    image: parkNShopImage,
  },
  {
    id: 'Thai Kau - free dessert coupon',
    name: 'Thai Kau - free dessert coupon',
    description: 'Redeem one free dessert with your HONG KONG DIRL PASS. *Minimum order required: HK$300',
    image: thaiKauImage,
  },
  {
    id: 'Blaze Craft - buy one, get one free coupon',
    name: 'Blaze Craft - buy one, get one free coupon',
    description: 'Redeem a buy one free one craft beer with your HONG KONG DIRL PASS.',
    image: blazeCrattImage,
  },
  {
    id: 'Chill Pill HK - free sweet potato fries coupon',
    name: 'Chill Pill HK - free sweet potato fries coupon',
    description:
      'Redeem one sweet potato fries with your HONG KONG DIRL PASS! *Minimum order required: at least one drink or dish',
    image: chillPillHKImage,
  },
  {
    id: 'Ellipsis Cafe & Lounge - free shot coupon',
    name: 'Ellipsis Cafe & Lounge - free shot coupon',
    description:
      'Redeem one free shot with your HONG KONG DIRL PASS. *Minimum order required: at least one drink or dish',
    image: EllipsisCafeImage,
  },
  {
    id: 'Enso - buy one, get one free coupon',
    name: 'Enso - buy one, get one free coupon',
    description: 'Redeem a free coupon with your HONG KONG DIRL PASS.',
    image: ensoImage,
  },
  {
    id: 'Lounge Hakuba - free cocktail coupon',
    name: 'Lounge Hakuba - free cocktail coupon',
    description:
      'Redeem one free house spirit single mix cocktail with your HONG KONG DIRL PASS! *Minimum order required: at least one drink or dish *After 6pm',
    image: loungeHakubaImage,
  },
  {
    id: 'Hoper - 50% off coupon for 2nd same item',
    name: 'Hoper - 50% off coupon for 2nd same item',
    description:
      'Redeem a second discounted craft beer with your HONG KONG DIRL PASS!  *The discount is for the 2nd same volume of beer',
    image: hoperImage,
  },
  {
    id: 'JESSY COFFEE SANDO - 50% off coupon for 1st drink',
    name: 'JESSY COFFEE SANDO - 50% off coupon for 1st drink',
    description:
      'Redeem a discounted first drink with your HONG KONG DIRL PASS. *Each person can only use the coupon once',
    image: jessyCoffeeSandoImage,
  },
  {
    id: 'MOZY Cafe & Bar - free beer coupon',
    name: 'MOZY Cafe & Bar - free beer coupon',
    description: 'Redeem one free beer with your HONG KONG DIRL PASS! *Minimum order required: any purchase',
    image: mozyCafeImage,
  },
  {
    id: 'ohm... cafe and bar - 50% off for coupon second cocktail',
    name: 'ohm... cafe and bar - 50% off for coupon second cocktail',
    description: 'Redeem a second discounted cocktail with your HONG KONG DIRL PASS.',
    image: ohmCafeImage,
  },
  {
    id: 'CURATOR Creative Cafe at M+ - 10% off coupon',
    name: 'CURATOR Creative Cafe at M+ - 10% off coupon',
    description:
      'Redeem one 10% off discount with your HONG KONG DIRL PASS. *Minimum order required: at least one drink',
    image: curatorCreativeCafeImage,
  },
  {
    id: 'Popmaze.cafe - buy one, get one free coupon (take out only)',
    name: 'Popmaze.cafe - buy one, get one free coupon (take out only)',
    description: 'Redeem a buy one free one coffee with your HONG KONG DIRL PASS. *Take out only',
    image: popmazeCafeImage,
  },
  {
    id: 'ROSEMA.ESE - 10% off coupon',
    name: 'ROSEMA.ESE - 10% off coupon',
    description: 'Enjoy a promo code of 10% off discount on online shop with your HONG KONG DIRL PASS.',
    image: rosemaImage,
  },
  {
    id: 'XIA - 10% off Main Menu Items & Full Price Merchandise coupon',
    name: 'XIA - 10% off Main Menu Items & Full Price Merchandise coupon',
    description: 'Enjoy a promo code of 10% off discount on online shop with your HONG KONG DIRL PASS.',
    image: xiaImage,
  },
  {
    id: 'CVIEW - 10% off Main Menu items & Buy 1 Get 1 Free on “Selected Wines” coupon',
    name: 'CVIEW - 10% off Main Menu items & Buy 1 Get 1 Free on “Selected Wines” coupon',
    description: 'Enjoy a promo code of 10% off discount on online shop with your HONG KONG DIRL PASS.',
    image: cviewmage,
  },
];
