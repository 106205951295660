import React, { useEffect, useRef, useState } from 'react';
import { Row, StyledIconImg } from '@/styles/common';
import { hongkongpassImage } from '@/styles/imgMapping';
import { Vendor } from '../vendors';
import {
  StyledButton,
  StyledContent,
  StyledCustomRow,
  StyledDescription,
  StyledLi,
  StyledList,
  StyledRow,
  StyledSubTitle,
  StyledTitle,
  StyledWrapper,
} from './Styles';

type Props = {
  vendor: Vendor;
};

const Card = ({ vendor }: Props) => {
  const [height, setHeight] = useState<number | undefined>();

  const imageRef = useRef<HTMLImageElement | null>(null);

  const handleGoApp = (url?: string) => {
    if (vendor.disabled) return;

    if (vendor.claimFunction && typeof vendor.claimFunction === 'function') {
      // TODO
    }

    if (url) {
      window.open(url);
    }
  };

  useEffect(() => {
    if (imageRef.current) {
      const { width } = imageRef.current.getBoundingClientRect();
      setHeight(width);
    }
  }, [imageRef.current]);

  return (
    <StyledWrapper>
      <StyledTitle id={vendor.id}>{vendor.name}</StyledTitle>
      <StyledCustomRow>
        <StyledIconImg ref={imageRef} src={vendor.image} width="312px" height={`${height}px`} />
        <StyledContent>
          <StyledSubTitle>About</StyledSubTitle>
          <StyledDescription dangerouslySetInnerHTML={{ __html: vendor.about }} />
          {vendor.howToJoin.length > 0 && <StyledSubTitle>How to join</StyledSubTitle>}
          <StyledList>
            {vendor.howToJoin.map((li, index) => (
              <StyledLi key={li}>{`${index + 1}. ${li}`}</StyledLi>
            ))}
          </StyledList>
          <StyledRow>
            <StyledButton disabled={vendor.disabled} onClick={() => handleGoApp(vendor.goToApp)}>
              {vendor.buttonText || 'Go to app'}
            </StyledButton>
            {vendor.buttonText2 && (
              <StyledButton onClick={() => handleGoApp(vendor.goToApp2)}>{vendor.buttonText2 || ''}</StyledButton>
            )}
          </StyledRow>
        </StyledContent>
      </StyledCustomRow>
    </StyledWrapper>
  );
};

export default Card;
