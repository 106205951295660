import { Method } from '../promise-request';
import type { GetTotalSupplyPayload } from './useGetTotalSupply';

const caller = ({ chainId }: GetTotalSupplyPayload) => {
  const version = 'v1';

  const url = `https://api-zogq3k52ja-uc.a.run.app/api/${version}/hongKongDirlPass/totalSupply`;
  return {
    method: Method.GET,
    url,
    data: {
      chainId,
    },
  };
};

export default caller;
