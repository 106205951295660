import React from 'react';
import { Card } from './Card';
import { StyledDescription, StyledTab, StyledTabWrapper, StyledTitle, StyledWrapper } from './Styles';
import { vendors } from './vendors';

const tabs = [
  {
    id: 'Web3 Gaming Expo HK',
    name: 'Web3 Gaming Expo HK',
  },
  {
    id: 'Sandbox',
    name: 'SANDBOX',
  },
  {
    id: 'Yuliverse',
    name: 'YULIVERSE',
  },
  {
    id: 'NFTPlay',
    name: 'NFTPlay',
  },
];

const Experience = () => (
  <StyledWrapper>
    <StyledTitle id="Experiences">EXPERIENCES IN DIGITAL-IRL.</StyledTitle>
    <StyledDescription>
      Participating in experiences have the opportunities to access various rewards.
    </StyledDescription>
    <StyledTabWrapper>
      {tabs.map((t) => (
        <StyledTab key={t.id} href={`#${t.id}`}>
          {t.name}
        </StyledTab>
      ))}
    </StyledTabWrapper>
    {vendors.map((vendor) => (
      <Card key={vendor.id} vendor={vendor} />
    ))}
  </StyledWrapper>
);

export default Experience;
