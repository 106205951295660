import React from 'react';
import { Row, StyledIconImg } from '@/styles/common';
import { titleLogoImage } from '@/styles/imgMapping';
import { StyledClaimText, StyledClaimWrapper, StyledContent, StyledGlitch, StyledWrapper } from './Styles';
import './glitch.scss';

const Banner = () => (
  <StyledWrapper>
    <StyledContent>
      <StyledGlitch className="glitch">
        <div className="channel r" />
        <div className="channel g" />
        <div className="channel b" />
      </StyledGlitch>
      <StyledClaimWrapper>
        <StyledIconImg src={titleLogoImage} width="469px" mobileWidth="100%" />
        <StyledClaimText
          dangerouslySetInnerHTML={{
            __html:
              'It’s all about Digital-IRL.<br>Claim the zero gas fee NFT pass to access it all.<br>#NFT Pass #COMMERCE #DEPIN #ERC6672',
          }}
        />
      </StyledClaimWrapper>
    </StyledContent>
  </StyledWrapper>
);

export default Banner;
