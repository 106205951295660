import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  max-width: 940px;
  width: 100%;
  margin: 150px auto 0 auto;
`;

export const StyledTitle = styled.div`
  ${fontStyle('36px', '400')};
  color: #ff0000;
  font-family: 'Anton';
  margin-bottom: 10px;

  @media ${XS_QUERY} {
    text-align: center;
  }
`;

export const StyledDescription = styled.div`
  ${fontStyle('16px', '400')};
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Roboto';
  margin-bottom: 150px;
  width: 446px;
  line-height: 24px;

  a {
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${XS_QUERY} {
    width: 100%;
  }
`;

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 46px;

  @media ${XS_QUERY} {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
`;
