import { Method } from '../promise-request';
import type { GetIsClaimablePayload } from './useGetIsClaimable';

const caller = ({ address, chainId }: GetIsClaimablePayload) => {
  const version = 'v1';

  const url = `/${version}/hongKongDirlPass/isClaimed`;
  return {
    method: Method.GET,
    url,
    data: {
      address,
      chainId,
    },
  };
};

export default caller;
